import Authentication from '@/commons/Authentication';

export default {
    data() {
        return {
            editedEntity: {},
            entityHandler: null,
            //   needRoles: [],

            styleProps: {
                windowWidth: "",
                windowHeight: "",
                pageOffset: 0,
                dialogOffset: 300,
                fullscreenDialogOffset: 64,
            },
            headerProps: {
                sortByText: this.$i18n.t('common.sortBy')
            },
        };
    },

    created() {
        this.styleProps.windowWidth = window.innerWidth;
        this.styleProps.windowHeight = window.innerHeight;
    },

    watch: {},

    //   mounted() {
    //     window.addEventListener('resize', () => {
    //       this.styleProps.windowHeight = window.innerHeight;
    //     });
    //   },

    computed: {
        // isDrawer() {
        //   return this.$parent.$parent.$parent.$parent.drawerState;
        // },
        // isOpen() {
        //   return this.value.open;
        // },
        // hasEditRight() {
        //   let editRights = this.needRoles.filter(role => role.endsWith(this.$constants.NRG_RIGHTS_EDIT_SUFFIX));
        //   let hasEditRight = false;
        //   editRights.forEach(right => {
        //     hasEditRight = this.hasRight(right);
        //     if (hasEditRight) return true;
        //   });
        //   return hasEditRight;
        // },
        // computedStep3Height() {
        //   return this.styleProps.windowHeight - this.styleProps.step3Offset;
        // },
        // computedStep4Height() {
        //   return this.styleProps.windowHeight - this.styleProps.step4Offset;
        // },
        computedFullScreenDialogHeight() {
            return this.styleProps.windowHeight - this.styleProps.fullscreenDialogOffset;
        },

        isMdScreen() {
            if (this.computedWidth < 832) {
                return true
            } else {
                return false
            }
        },

        isMiniForm() {
            if (this.computedWidth < 420) {
                return true
            } else {
                return false
            }
        },

        isMiniTable() {
            if (this.computedWidth < 600) {
                return true
            } else {
                return false
            }
        },
        tableOffset() {
            if (this.isMiniTable) {
                return 124;
            } else {
                return 72;
            }
        },
        computedWidth() {
            return this.styleProps.windowWidth;
        },
        computedHeight() {
            return this.styleProps.windowHeight;
        },
    },

    methods: {
        closeDialog() {
            this.value.edit = false;
            this.value.details = false;
            this.$emit('input', this.value)
        },

        clearDialog() {
            //this.$refs.form.resetValidation();
        },

        openDialogNew() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - openDialogNew');
            this.clearDialog();
            this.editedEntity = this.entityHandler.createEntityObj();
            this.value.edit = false;
            this.value.details = true;
        },

        openDialogDetails(entity) {
            //this.clearDialog();
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - openDialogDetails');
            this.$log.debug(entity);
            this.editedEntity = this.entityHandler.createEntityObj(entity);
            this.$log.debug(this.editedEntity);
            this.value.edit = true;
            this.value.details = true;
        },

        _saveEditedEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObj(editedEntity)
        },

        _saveNewEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObj(editedEntity)
        },

        saveNewEntity() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - saveNewEntity');
            if (this.$refs.form.validate()) {
                const newEntity = this._saveNewEntityCreateEntityObj(this.editedEntity);

                this.entityHandler
                    .saveEntity(newEntity)
                    .then(response => {
                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                        this.$parent.fetchEntities();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => { });
            } else {
                const invalidField = this.$refs.form.inputs.filter(item => item.valid === false);
                if (invalidField) {
                    this.$nextTick(() => invalidField[0].focus());
                }
                this.$store.commit('setStatusCode', 'invalidFormData');
                this.$store.commit('setSnackbarEnabled', true);
            }
        },

        saveEditedEntity() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - saveEditedEntity');
            if (this.$refs.form.validate()) {
                this.$log.debug(this.editedEntity);
                const editedEntity = this._saveEditedEntityCreateEntityObj(this.editedEntity);

                this.entityHandler
                    .updateEntity(editedEntity, false)
                    .then((response) => {
                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                        this.$parent.fetchEntities();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => { });
            } else {
                const invalidField = this.$refs.form.inputs.filter(item => item.valid === false);
                if (invalidField) {
                    this.$nextTick(() => invalidField[0].focus());
                }
                this.$store.commit('setStatusCode', 'invalidFormData');
                this.$store.commit('setSnackbarEnabled', true);
            }
        },

        hasRight(right = "") {
            return Authentication.hasRight(right);
        },

        needRolesForMenu(name) {
            return this.$router.options.routes.find(el => el.name == name).meta.requiredRoles;
        },
    }
};

