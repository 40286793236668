import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';


export class ProductEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.PRODUCT,
        save: URLs.BANK_PRODUCT,
        update: URLs.BANK_PRODUCT,
        delete: URLs.BANK_PRODUCT,
        getEntity: URLs.BANK_PRODUCT
    };

    // partnerId = null;

    constructor(log, store) {
        super(log, store);
        // this.partnerId = partnerId;
    }

    // setPartnerId(partnerId) {
    //     this.log.debug("=============== setPartnerId ================", partnerId);
    //     this.partnerId = partnerId;
    // }

    createEntityObj(entity = null) {
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.bank_id = isPresent(entity) && isPresent(entity.bank_id) ? entity.bank_id : '';
        returnObj.name = isPresent(entity) && isPresent(entity.name) ? entity.name : '';
        returnObj.exam = isPresent(entity) && isPresent(entity.exam) ? entity.exam : 'N';
        returnObj.comment = isPresent(entity) && isPresent(entity.comment) ? entity.comment : '';
        returnObj.active = isPresent(entity) && isPresent(entity.active) ? entity.active : 'Y';

        return returnObj;
    }


    get(entity, idFieldName = 'id') {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - get: ', entity);
        let url = this.apiURLs.getEntity;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.bank_id)) {
            let bankId = entity.bank_id; 
            url = eval(`\`${this.apiURLs.getEntity}\``);
        }
        return utils.apiCaller(constants.HTTP_METHODS.GET, url + "/" + entity[idFieldName])
    }

    save(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - save');
        let url = this.apiURLs.save;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.bank_id)) {
            let bankId = entity.bank_id; 
            url = eval(`\`${this.apiURLs.save}\``);
        }
        // return utils.apiCaller(constants.HTTP_METHODS.POST, this.apiURLs.save + "/" + entity.id, entity) //fixme: remove it
        return utils.apiCaller(constants.HTTP_METHODS.POST, url, entity)
    }

    update(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - update');
        let url = this.apiURLs.update;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.bank_id)) {
            let bankId = entity.bank_id; 
            url = eval(`\`${this.apiURLs.update}\``);
        }
        url = url + "/" + entity.id;
        return utils.apiCaller(constants.HTTP_METHODS.PUT, url, entity)
    }

    delete(entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - delete');
        this.log.debug(entity);
        let url = this.apiURLs.delete;
        //fixme: nem kell, h ha lesz vegpont amivel dierkben lekerdezheto 1 agent
        if (isPresent(entity.bank_id)) {
            let bankId = entity.bank_id; 
            url = eval(`\`${this.apiURLs.delete}\``);
        }
        url = url + "/" + entity.id;
        return utils.apiCaller(constants.HTTP_METHODS.DELETE, url)
    }

    listAll() {
        let url = this.apiURLs.listAll;        
        this.log.debug('EntityBase of ' + this.constructor.name + ' - listall [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

}
