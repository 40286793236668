import DataComponentBase from '@/commons/DataComponentBase';
import ProductDetails from '@/components/Products/ProductDetails';
import { ProductEntity } from './ProductEntity';
import { AgentEntity } from '@/components/Agents/AgentEntity';
import { BankEntity } from '@/components/Banks/BankEntity';
import moment from 'moment-timezone';
import VerificationDialog from '@/components/CustomVerificationDialog';


export default {
    name: 'ProductList',
    extends: DataComponentBase,
    components: {
        ProductDetails,
        VerificationDialog
    },
    props: {
        actions: {
            type: Boolean,
            default: true,
        },
        checkboxes: {
            type: Boolean,
            default: false,
        },
        entity: {
            type: Object,
            default: null,
        },
        entityType: {
            type: String,
            default: null,
        },
        examValidityEdit: {
            type: Boolean,
            default: false,
        },
        bankId: {
            type: String,
            default: null,
        },
        extraOffset: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            headerFilters: {
                id: "",
                active: "all",
                name: "",
                bank_display_name: "",
                examValidity: "",
            },
            selectedItem: {},
            entityHandler: new ProductEntity(this.$log, this.$store),
            agentEntityHandler: new AgentEntity(this.$log, this.$store),
            bankEntityHandler: new BankEntity(this.$log, this.$store),
            examValidityList: [],
            examValidityDateMenu: {},
            tableItems: []
        }
    },

    created() {

    },

    computed: {
        headers() {
            let headers = [
                {
                    text: this.$i18n.t('common.id'),
                    align: "right",
                    value: "id",
                    class: "width-89",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['id'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t("common.status"),
                    value: "active",
                    class: "width-105",
                    filterType: "selectSimple",
                    items: [
                        { text: this.$i18n.t("common.all"), value: "all" },
                        { text: this.$i18n.t("common.active"), value: "Y" },
                        { text: this.$i18n.t("common.inactive"), value: "N" },
                    ],
                    filter: f => {
                        if (this.headerFilters['active'] != "all") {
                            return f == this.headerFilters['active'];
                        } else {
                            return true;
                        }
                    }
                },
                {
                    text: this.$i18n.t('common.name'),
                    align: "left",
                    value: "name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.bank'),
                    align: "left",
                    value: "bank_display_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['bank_display_name'].toLowerCase()) }
                },
            ];

            if (this.examValidityEdit) {
                headers.push(
                    {
                        text: this.$i18n.t('common.expirationDate'),
                        value: "examValidity",
                        align: "left",
                        filterType: "text",
                        filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['examValidity'].toLowerCase()) }
                    }
                )
            }

            if (this.actions) {
                headers.push(
                    {
                        text: "",
                        value: "actions",
                        sortable: true,
                        align: "center",
                        class: "action-column-2",
                    }
                )
            }

            return headers;
        },
        loadedEntities() {
            this.tableItems = [];
            this.entityHandler.entityItems.forEach(el => {
                let tmpItem = { ...el };
                let actualItem = this.examValidityList.find(x => x.product_id == tmpItem.id);
                if (actualItem) {
                    tmpItem.examValidity = actualItem.expiration_date;
                    tmpItem.original_examValidity = actualItem.expiration_date;
                } else {
                    tmpItem.examValidity = "";
                    tmpItem.original_examValidity = "";
                }
                this.tableItems.push(tmpItem)
            })
            return this.tableItems
        },
    },

    methods: {
        fetchEntities() {
            if (this.entityType == "BANK") {
                this.loadEntityDataByBankId(this.entity);
            } else if (this.entityType == "AGENT") {
                this.loadEntityData(this.entity);
            } else {
                if (this.entityHandler) {
                    this.entityHandler.fetchEntities();
                }
            }
        },

        saveProductValidityDate(item) {
            console.log("#### saveProductValidityDate", item);
            item.changed = false;
        },

        getStatus(isActive) {
            switch (isActive) {
                case "Y":
                    return { color: "success", text: "common.active" };
                    break;
                case "N":
                    return { color: "#525252", text: "common.inactive" };
                    break;
                default:
                    return { color: "error", text: "common.na" };
                    break;
            }
        },
        getExpirationDateState(expirationDate) {
            if (moment(expirationDate).isSameOrAfter(moment().add(7, 'days'))) {
                return { color: "success", icon: "mdi-circle" };
            } else if (moment(expirationDate).isSameOrAfter(moment())) {
                return { color: "warning", icon: "mdi-circle" };
            } else if (moment(expirationDate).isBefore(moment())) {
                return { color: "error", icon: "mdi-circle" };
            } else {
                return { color: "", icon: "mdi-circle" };
            }
        },

        loadEntityData(entity) {
            this.examValidityList = [];
            this.entityHandler.listAll().then(entityData => {
                this.entityHandler.entityItems = entityData.data.data;
            });
            this.agentEntityHandler.productList(entity).then(entityData => {
                this.examValidityList = entityData.data.data;
            });
        },

        loadEntityDataByBankId(entity) {
            this.bankEntityHandler.productList(entity).then(entityData => {
                this.entityHandler.entityItems = entityData.data.data;
            });
        },

    },

    watch: {
        'dialog.details'() {
            if (!this.dialog.details) {
                this.closeDialog();
            }
        },
        entity() {
            this.fetchEntities();
        },
        tableItems: {
            handler: function (val, oldVal) {
                // Return the object that changed
                if (oldVal && oldVal.length > 0 && val.length == oldVal.length) {
                    var changed = val.filter(function (p, idx) {
                        return p["examValidity"] !== oldVal[idx]["original_examValidity"]
                    })
                    if (changed)
                        this.$emit("update-products-list", val);
                }
            },
            deep: true
        },
    }

}
