//import DataComponentBase from '@/commons/DataComponentBase';
import { ProductEntity } from '@/components/Products/ProductEntity';
import DialogBase from '@/commons/DialogBase';
import { BankEntity } from '@/components/Banks/BankEntity';


export default {
    name: 'ProductDetails',
    extends: DialogBase,
    components: {

    },
    props: {
        value: {
            type: Object,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            isActive: true,
            form: {
                general: true,
                table: true,
            },
            // tableItems: [],
            headerFilters: {
                active: "all",
                firmName: "",
                name: "",
                comment: "",
            },
            entityHandler: new ProductEntity(this.$log, this.$store),
            banks: [],
            bank: {
                list: [],
                showDetails: null,
                options: [],
                selected: {
                },
            },
            bankEntityHandler: new BankEntity(this.$log, this.$store),
        }
    },

    created() {
        this.initBanks()
    },

    computed: {
        isEdit() {
            return this.value.edit;
        },
        isOpenDetails() {
            return this.value.details;
        }
    },

    methods: {
        initBanks() {
            this.bankEntityHandler.listAll().then(entities => {
                this.banks = entities.data.data;
            });

            // this.bank.list = require('@/data/banks.json').data;
            // this.bank.selected = this.bank.list.find(el => el.id == this.editedEntity.bankId)
            // this.bank.options = this.bank.list.map(el => el.displayName)
        },

        toggleBankDetails() {
            if (this.bank.showDetails == null) {
                this.initBanks();
                this.bank.showDetails = 0;
            } else {
                this.bank.showDetails = null;
            }
        },

        openDialogDetails(entity) {
            this.value.edit = true;
            this.value.details = true;
            this.loadEntityData(entity);
        },

        loadEntityData(entity) {
            this.entityHandler.getEntity(entity).then(entityData => {
                this.editedEntity = this.entityHandler.createEntityObj(entityData);
            });
            // this.$forceUpdate();
        },
    },

    watch: {
        isOpenDetails() {
            if (this.isOpenDetails) {
                this.form.general = true;
                this.form.table = true;

                if (this.isEdit) {
                    this.openDialogDetails(this.entity);
                } else {
                    this.openDialogNew(this.entity);
                }

                //this.$nextTick(() => this.$refs.agent_form_firm_name.focus());

            }
        },

        //   value() {
        //     if(this.value) {
        //       this.form.general = true;
        //       this.form.table = true;

        //       this.initBanks();
        //       this.initIsActive();
        //     }
        //   },
        //   isActive() {
        //     if(this.isActive) {
        //       this.editedEntity.active == 'Y'
        //     } else {
        //       this.editedEntity.active == 'N'
        //     }
        //   },

        //   'editedEntity.bankDisplayName'() {
        //     if(!!this.bank.list.find(el => el.displayName == this.editedEntity.bankDisplayName)) {
        //       this.editedEntity.bankId = this.bank.list.find(el => el.displayName == this.editedEntity.bankDisplayName).id
        //     }
        //     this.initBanks();
        //   },
    }

}
