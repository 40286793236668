<template>
    <v-dialog
      v-model="value.delete"
      persistent
      max-width="400">
      <v-card>
        <v-card-title>
          {{ $t("common.delete") }}
        </v-card-title>
        <v-card-text class="pt-0 text-justify">
          {{ $t("message.deleteBegin") }} <v-chip label dark class="chip-size-small">{{ itemName }}</v-chip> {{ $t("message.deleteEnd")}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="closeDialog()">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click.native="$emit('verify')">
            {{ $t("common.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>  
    export default {
      props: {
        value: { 
          type: Object,
          required: true
        },
        itemName: {
          type: String,
          required: true
        }
      },
      methods:{
          closeDialog() {
              this.value.delete = false;
              this.$emit('input', this.value);
          }
      }
    }
  </script>