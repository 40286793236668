import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';


export class BankEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.BANK,
        save: URLs.BANK,
        update: URLs.BANK,
        delete: URLs.BANK,
        productList: URLs.BANK_PRODUCT,
    };

    constructor(log, store) {
        super(log, store);
    }

    createEntityObj(entity = null) {
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.name = isPresent(entity) && isPresent(entity.name) ? entity.name : '';
        returnObj.display_name = isPresent(entity) && isPresent(entity.display_name) ? entity.display_name : '';
        returnObj.active = isPresent(entity) && isPresent(entity.active) ? entity.active : 'Y';
        returnObj.products = isPresent(entity) && isPresent(entity.products) ? entity.products : [];

        return returnObj;
    }

    productList(entity, idFieldName = 'id') {
        let bankId = entity.id;
        let url = eval(`\`${this.apiURLs.productList}\``);

        this.log.debug('EntityBase of ' + this.constructor.name + ' - productList [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }
}
